<template>
  <div class="match-height align-items-start row student-dashboard">
    <b-modal id="agree-modal" v-model="showModal" title="Agreement" size="lg" @ok="handleAgree">
      <div>
      <div class="policy-text">
      <h3>Introduction:</h3>
      <p>
        The information given in this booklet is based on the information received from the respective overseas institutions at the time
        of signing the MOU and received before printing this booklet. Any changes in the circumstances if known to Aliff Overseas Consultants (further mentioned only Aliff) well in advance would be informed to the students immediately. However, any changes in the circumstances due to the respective country's policies is not the responsibility and liability of Aliff.
      </p>
      
      <h3>Definition:</h3>
      <ul>
        <li><strong>Aliff</strong> or <strong>we</strong> means Aliff Overseas Consultants.</li>
        <li><strong>Student</strong> means any person intending to enroll for any course/training in any overseas institute/training provider.</li>
        <li><strong>Overseas Institute</strong> means International University/College/School/Institute or any other educational institute in respective country apart from the country of origin (India).</li>
        <li><strong>Independent Contractors</strong> means any person, company, organization or firm providing any type of services to the students and not in direct control of Aliff Overseas Consultants.</li>
      </ul>

      <h3>Liability & Responsibility:</h3>
      <ul>
        <li>We act as a consultant for overseas institutes to facilitate local (Indian) students who wish to study abroad.</li>
        <li>Our liability and responsibility cease once we explain the details of the desired course or overseas institute.</li>
        <li>Decisions taken by the overseas institute/training provider are final, and Aliff has no control over them.</li>
        <li>Aliff is not responsible for any delays, cancellations, or hardships caused by third parties.</li>
        <li>We will use student feedback, video, and pictures for promotional activities.</li>
      </ul>

      <h3>General Information:</h3>
      <ul>
        <li>Students are not bound to enroll in any program or university mentioned.</li>
        <li>Submitting an application does not guarantee admission.</li>
        <li>Delays in application processing are the responsibility of the student.</li>
        <li>Any changes in contact details must be communicated to Aliff to avoid miscommunication.</li>
        <li>The student must comply with all rules and regulations of the overseas institute.</li>
      </ul>

      <h3>Application Fee & Refund Policies:</h3>
      <ul>
        <li>Payment of application fees does not constitute a contract between the student and the overseas institute.</li>
        <li>Non-refundable registration fees apply unless otherwise stated.</li>
        <li>All financial figures provided are indicative and subject to change.</li>
        <li>Students are responsible for ensuring they have sufficient financial resources to cover their study/training period.</li>
      </ul>

      <h3>Educational Fee & Refund Policies:</h3>
      <ul>
        <li>All fees mentioned are subject to change based on exchange rates and institute policies.</li>
        <li>Failure to complete a course does not qualify for a refund.</li>
        <li>Aliff has no control over refund decisions made by overseas institutes.</li>
      </ul>
      </div>
      </div>
      <b-form-checkbox v-model="agreed">I agree</b-form-checkbox>
    </b-modal>
    <div class="col-lg-12">
      <profile-status :profile_data="profile_data" />
    </div>
    <div class="col-12">
      <DemoMaterials />
    </div>
    <div class="col-12">
      <EducationLoan />
    </div>
    <div class="col-12">
      <counts :data="counts" />
    </div>
    <div class="col-lg-5">
      <add-reference />
    </div>
    <!-- <div class="col-lg-6">
      <study-timeline />
    </div> -->
    <div class="col-lg-12">
      <assigned-staff :assigned_staff="assigned_staff" />
    </div>
    <div class="col-12">
      <study-abroad-journey />
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, BModal, BFormCheckbox } from "bootstrap-vue";
import "./style.css";

import ProfileStatus from "./helper/ProfileStatus.vue";
import DemoMaterials from "./helper/DemoMaterials.vue";
import AssignedStaff from "./helper/AssignedStaff.vue";
import StudyTimeline from "./helper/StudyTimeline.vue";
import StudyAbroadJourney from "./helper/StudyAbroadJourney.vue";
import StudentServices from "@/apiServices/StudentServices";
import AddReference from "./helper/AddReference.vue";
import Counts from "./helper/Counts.vue";
import EducationLoan from "./helper/EducationLoan.vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ProfileStatus,
    AssignedStaff,
    StudyTimeline,
    StudyAbroadJourney,
    AddReference,
    Counts,
    DemoMaterials,
    EducationLoan,
    // DocumentStatus,
    // ApplicantsList,
    // Events,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BModal,
    BFormCheckbox,
    ToastificationContentVue
  },

  data() {
    return {
      showModal: false,
      profile_data: {},
      assigned_staff: [],
      applications: {},
      counts: {},
      agreed: false
    };
  },
  methods: {
    async GetHomePageData() {
      StudentServices.getHomePageData()
        .then((response) => {
          const data = response.data.data;
          this.profile_data = data.profile_data;
          this.applications = data.applications;
          this.assigned_staff = data.assigned_staff;

          this.counts = {
            course_shortlisted: data.course_shortlisted,
            applications: data.applications,
            offer_letters_received: data.offer_letters_received,
            awaiting_offer_letter: data.awaiting_offer_letter,
          };
        })
        .catch((err) => {
          console.log("Error getting home page data ", err);
        });
    },
    async handleAgree() {
      try {
        const response = await StudentServices.saveStudentAcceptance({
          user_acceptance: this.agreed ? 'Y' : 'N'
        })
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "MinusCircleIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log("Error saving user acceptance ", error);
      }
    },
    async checkUserAcceptance() {
      try {
        const response = await StudentServices.checkStudentAcceptance()
        if (response.data.status) {
          this.agreed = response.data.data
          if (this.agreed == 'N') {
            this.showAcceptanceModal();
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    showAcceptanceModal() {
      if (!this.showModal) {
        this.showModal = true
      }
    }
  },
  beforeMount() {
    this.GetHomePageData();
    this.checkUserAcceptance();
  },
};
</script>

<style scoped>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}
</style>
